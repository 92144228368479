import React, { ReactNode, forwardRef } from "react";
import styled, { DefaultTheme } from "styled-components";

type HexCardVariant = "flat" | "subtle" | "default";

export interface HexCardProps {
  className?: string;
  children: ReactNode;
  interactive?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  elevation?: HexCardVariant;
}

function cardShadow(theme: DefaultTheme, variant?: HexCardVariant): string {
  switch (variant) {
    case "subtle":
      return theme.boxShadow.CARD_SUBTLE;
    case "flat":
      return "none";
    default:
      return theme.boxShadow.CARD;
  }
}

function cardHoverShadow(
  theme: DefaultTheme,
  variant?: HexCardVariant,
): string {
  switch (variant) {
    case "subtle":
      return theme.boxShadow.CARD_SUBTLE_HOVER;
    case "flat":
      return theme.boxShadow.CARD_FLAT_HOVER;
    default:
      return theme.boxShadow.CARD_HOVER;
  }
}

export const BaseHexCard = styled.div<HexCardProps>`
  padding: 20px;
  background-color: ${({ theme }) => theme.backgroundColor.DEFAULT};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ elevation, theme }) => cardShadow(theme, elevation)};
  cursor: ${({ interactive }) => (interactive ? "pointer" : undefined)};

  transition:
    box-shadow ${({ theme }) => theme.animation.durationMedium}
      ${({ theme }) => theme.animation.easing},
    background-color ${({ theme }) => theme.animation.durationMedium}
      ${({ theme }) => theme.animation.easing};

  &:active {
    box-shadow: ${({ interactive, theme }) =>
      interactive ? theme.boxShadow.CARD : undefined};
  }

  &:hover {
    box-shadow: ${({ elevation, interactive, theme }) =>
      interactive ? cardHoverShadow(theme, elevation) : undefined};
  }

  border: ${({ elevation, interactive, theme }) =>
    elevation === "flat" && interactive
      ? `1px solid ${theme.borderColor.MUTED}`
      : undefined};
`;

type AllProps = HexCardProps &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const HexCard = forwardRef<HTMLDivElement, AllProps>(function HexCard(
  {
    children,
    className,
    elevation,
    interactive = false,
    onClick,
    ...otherProps
  }: HexCardProps,
  ref,
) {
  return (
    <BaseHexCard
      ref={ref}
      className={className}
      elevation={elevation}
      interactive={interactive}
      onClick={onClick}
      {...otherProps}
    >
      {children}
    </BaseHexCard>
  );
});
